<script>
    import { onMount } from "svelte";
    import { fly } from "svelte/transition";
    import { quartInOut } from "svelte/easing";

    export let images;
    export let autoSlide;

    let wrapperElem;
    let width;

    let direction = 1;
    let index = 0;
    let intervalID;

    function next(e) {
        e.preventDefault();
        direction = 1;
        index = (index + 1) % images.length;
    }

    function prev(e) {
        e.preventDefault();
        direction = -1;
        index = (images.length + index - 1) % images.length;
    }

    function flyOut(
        node,
        { delay = 0, duration = 800, easing: easing$1 = quartInOut, x = 0, y = 0, opacity = 0, }
    ) {
        x = width * direction * -1;

        const style = getComputedStyle(node);
        const target_opacity = +style.opacity;
        const transform = style.transform === "none" ? "" : style.transform;
        const od = target_opacity;

        return {
            delay,
            duration,
            easing: easing$1,
            css: (t, u) => `
                    transform: ${transform} translate(${(1 - t) * x}px, ${
        (1 - t) * y
      }px);
                    opacity: ${target_opacity}`,
        };
    }

    function flyIn(
        node,
        { delay = 0, duration = 800, easing: easing$1 = quartInOut, x = 0, y = 0, opacity = 0, }
    ) {
        x = width * direction;

        const style = getComputedStyle(node);
        const target_opacity = +style.opacity;
        const transform = style.transform === "none" ? "" : style.transform;
        const od = target_opacity;

        return {
            delay,
            duration,
            easing: easing$1,
            css: (t, u) => `
                transform: ${transform} translate(${(1 - t) * x}px, ${ (1 - t) * y}px);
                opacity: ${target_opacity}`,
        };
    }

    function startSliding(e) {
        intervalID = setInterval(function () {
            index = (images.length + index - 1) % images.length;
        }, autoSlide);
    }

    function stopSliding(e) {
        clearInterval(intervalID);
    }

    function handleResize(e) {
        updateWidth();
    }

    function updateWidth() {
        width = wrapperElem.getBoundingClientRect().width;
    }

    if (autoSlide !== false) {
        onMount(startSliding);
    }

    onMount(() => {
        updateWidth();
    });
</script>

<svelte:window on:resize={handleResize} />

<div class="site-banner__images"
       bind:this={wrapperElem}
       on:mouseenter={stopSliding}
       on:mouseleave={startSliding}>

    {#each [images[index]] as image (image.ID)}
        {#if image}
            {#if image.Url}
                <div
                    in:flyIn={{ duration: 1000 }}
                    out:flyOut={{ duration: 1000 }}
                    class="site-banner__image"
                    style="background-image: url('{image.Url}');"></div>
            {/if}
        {/if}
    {/each}

    {#if images.length > 1}
        <a class="prev" href="#prev" on:click={prev}>
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
            </svg>
        </a>

        <a class="next" href="#next" on:click={next}>
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
        </a>
    {/if}
</div>
